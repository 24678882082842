<script setup lang="ts">
import GraphCard from '@app/components/dashboard/GraphCard.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import { getKeyFromLegendItem } from '@app/composables/chartjs/useLineChartData'
import { useLocale } from '@app/composables/useLocale'
import type { LegendItem } from '@app/types/metrics'
import type { WorkType } from '@app/types/shared'
import { chartDefaults } from '@app/utils/chartSettings'
import { applyFilter } from '@app/utils/filter'
import { ucfirst } from '@app/utils/transform'
import without from 'lodash/without'
import { computed } from 'vue'
import { route } from 'ziggy-js'

type Location = {
    name: string
    slug: string | null
}

const { graphs, locations, channelCategories, workTypes, filters } = defineProps<{
    locations: Location[]
    graphs: Array<{ type: string; filters: string[] }>
    filters: Record<string, unknown>
    workTypes: WorkType[]
    channelCategories: string[]
}>()

const { translate } = useLocale()

const selectedGraphs = computed(() => {
    if (!filters.graph || !Array.isArray(filters.graph)) {
        return graphs
    }

    return graphs.filter((graph) => filters.graph.includes(graph.type))
})

const selectedLocations = computed(() => {
    if (!filters.location || !Array.isArray(filters.location)) {
        return locations
    }

    return locations.filter((location) => filters.location.includes(location.slug))
})

const graphFilters = computed(() =>
    graphs.map((item) => ({
        name: `dashboard.kpi.${item.type}`,
        slug: item.type,
    }))
)

const channelCategoryFilters = computed(() =>
    channelCategories.map((item) => ({
        name: ucfirst(item),
        slug: item,
    }))
)

const filterKey = computed(() => btoa(JSON.stringify(filters)))

chartDefaults()

const spans = ['col-span-1', 'col-span-2', 'col-span-3', 'col-span-4']

const graphRows = computed(() => {
    return spans[selectedGraphs.value.length - 1]
})

function getDefaultFilterValues(name: string) {
    switch (name) {
        case 'channel_category':
            return channelCategories
        case 'work_type_id':
            return workTypes.map((item) => item.slug)
        default:
            return []
    }
}

function onLegendClick(name: string, item: LegendItem) {
    let currentFilter = route().queryParams[name] ?? getDefaultFilterValues(name)

    if (!Array.isArray(currentFilter)) {
        return
    }

    const key = getKeyFromLegendItem(item)

    if (currentFilter.includes(key)) {
        currentFilter = without(currentFilter, key)
    } else {
        currentFilter.push(key)
    }

    applyFilter({ [name]: currentFilter })
}
</script>

<template>
    <!-- <ContentTabs :items="tabs" bottomBorder /> -->
    <div class="flex items-end gap-4 pb-4" :key="filterKey">
        <DropdownMultiFilter :items="locations" slug="location" by="slug" class="!min-w-40" :label="$t('attributes.location')" />
        <DropdownMultiFilter :items="graphFilters" slug="graph" by="slug" class="!min-w-40" :label="$t('dashboard.attributes.graph')" />
        <DropdownMultiFilter :items="workTypes" slug="work_type_id" by="slug" class="!min-w-40" :label="$t('attributes.work_type')" />
        <DropdownMultiFilter :items="channelCategoryFilters" slug="channel_category" by="slug" class="!min-w-40" :label="$t('attributes.channel_type')" />

        <ResetFilterButton :href="route('poc.dashboard.index')" />
    </div>

    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
        <div v-for="location in selectedLocations" :class="graphRows">
            <div class="mb-4 text-lg font-medium leading-6 text-zinc-800">{{ translate(location.name) }}</div>
            <div class="flex flex-col gap-4 lg:flex-row">
                <GraphCard
                    v-for="graph in selectedGraphs"
                    :graph
                    :filters
                    :location="location.slug"
                    :key="`${graph.type}-${location.slug}`"
                    @click:legend="onLegendClick"
                />
                <!-- <GraphCard v-for="(kpi, type) in graph.kpi" :type :graph="kpi" :key="`${graph.name}-${kpi.name}`" /> -->
            </div>
        </div>
    </div>

    <div class="flex flex-col flex-wrap gap-4">
        <!-- <div class="flex justify-between gap-4">
            <LargeGraph title="KPI card" range="Last month" :lineColor="tailwindColors.fonkyBlue" :dotColor="tailwindColors.fonkyBlue" />
        </div> -->
    </div>
</template>

<style lang="css">
/** @see https://github.com/chartjs/Chart.js/issues/11005 */
canvas {
    width: 100% !important;
    height: 100% !important;
}
</style>
