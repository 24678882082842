import type { GraphData, LegendItem } from '@app/types/metrics'
import { tailwindColors } from '@app/utils/chartSettings'
import { createColorPicker } from '@app/utils/colors'
import { type ChartOptions } from 'chart.js'
import { computed } from 'vue'
import { route } from '../../../../vendor/tightenco/ziggy/src/js'
import { useLocale } from '../useLocale'

const lineColors = [tailwindColors.fonkyBlue, tailwindColors.fonkyOrange, 'green']

export function getKeyFromLegendItem(item: LegendItem) {
    return typeof item === 'string' ? item : item.slug
}

type Options = {
    onLegendClick(item: LegendItem): void
}

export function useLineChartData(graph: GraphData, options: Options) {
    const { dbTranslate } = useLocale()
    const showVerticalGrid = computed(() => graph.data.datasets[0]?.data?.length < 10)

    const legendKeys = computed(() => graph.legend.items.map(getKeyFromLegendItem))

    const selectedLegendItems = computed(() => {
        const selected = route().queryParams[graph.legend.name] ?? legendKeys.value

        return Array.isArray(selected) ? selected : [selected]
    })

    const allLegendItemsSelected = computed(() => !route().queryParams[graph.legend.name])

    const chartOptions: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            x: {
                grid: {
                    display: showVerticalGrid.value,
                },
                border: {
                    color: tailwindColors.zinc300,
                },
                ticks: {
                    callback(value, index, ticks) {
                        if (ticks.length > 10 && index > 0 && index < ticks.length - 1) {
                            return ''
                        }

                        return this.getLabelForValue(value)
                    },
                    maxRotation: 0,
                    minRotation: 0,
                    color: tailwindColors.zinc400,
                    autoSkip: false,
                    includeBounds: true,
                    font: {
                        size: 12,
                    },
                },
            },
            y: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
        },
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    labelColor(tooltipItem) {
                        return {
                            backgroundColor: tooltipItem.dataset.backgroundColor,
                        }
                    },
                },
            },
            legend: {
                position: 'top',
                align: 'end',
                labels: {
                    font: {
                        size: 12,
                    },
                    boxWidth: 12,
                    boxHeight: 1,
                    generateLabels(chart) {
                        return graph.legend.items.map((item, index) => ({
                            text: typeof item === 'string' ? item : dbTranslate(item.name),
                            strokeStyle: chart.data.datasets[index].backgroundColor,
                            hidden: !allLegendItemsSelected.value && !selectedLegendItems.value.includes(getKeyFromLegendItem(item)),
                            index,
                        }))
                    },
                },

                onClick(event, legendItem, legend) {
                    const selectedLegendItem = graph.legend.items[legendItem.index] as LegendItem | null

                    if (!selectedLegendItem) return

                    options.onLegendClick(selectedLegendItem)
                },
            },
        },
        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        tension: 0.5,
        pointRadius: 10,
        pointHoverRadius: 6,
    }

    const colorGenerator = createColorPicker(lineColors)

    const chartData = computed(() => {
        return {
            ...graph.data,
            datasets: graph.data.datasets.map((item, index) => {
                const dataset = {
                    tension: 0.4,
                    ...item,
                }

                if (!dataset.backgroundColor) {
                    const color = colorGenerator.next().value

                    dataset.backgroundColor = color
                    dataset.borderColor = color
                }

                const selectedLegendItem = graph.legend.items[index] as LegendItem | null

                dataset.hidden = !allLegendItemsSelected.value && !selectedLegendItems.value.includes(getKeyFromLegendItem(selectedLegendItem))

                return dataset
            }),
        }
    })

    return {
        chartData,
        options: chartOptions,
    }
}
