<script setup lang="ts">
import { useLineChartData } from '@app/composables/chartjs/useLineChartData'
import type { GraphData } from '@app/types/metrics'
import { chartDefaults } from '@app/utils/chartSettings'
import { toRefs } from 'vue'
import { Line } from 'vue-chartjs'

chartDefaults()

const props = defineProps<{
    type: string
    graph: GraphData
}>()

const emit = defineEmits(['click:legend'])

const { graph } = toRefs(props)

const { chartData, options } = useLineChartData(graph.value, {
    onLegendClick(item) {
        emit('click:legend', graph.value.legend.name, item)
    },
})
</script>

<template>
    <Line :data="chartData" :options="options" />
</template>
